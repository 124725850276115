<template>
  <div class="filtering">
    <div class="date-select">
      <b-field label="เริ่มต้น">
        <b-datepicker
          placeholder="เริ่มต้น"
          v-model="filter.start"
          style="max-width: 400px"
          icon="calendar-day"
        ></b-datepicker>
      </b-field>
      <b-field label="สิ้นสุด"
        ><b-datepicker
          placeholder="สิ้นสุด"
          v-model="filter.end"
          style="max-width: 400px"
          icon="calendar-day"
        ></b-datepicker
      ></b-field>
    </div>
    <div class="action">
      <b-button
        @click="onApplyFilter"
        :disabled="
          filter.start == null ||
          filter.end == null ||
          filter.start > filter.end
        "
        :loading="filterLoading"
        type="is-primary"
        >{{ $t("Apply") }}</b-button
      >
      <b-button @click="onResetFilter" type="is-light">{{
        $t("Clear")
      }}</b-button>
      <!-- <b-button @click="onExportCSV" disabled type="is-success is-light">{{
            $t("Export")
          }}</b-button> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "TableFilter",
  props: ["filterLoading"],
  data() {
    return {
      filter: {
        start: null,
        end: null,
      },
    };
  },
  methods: {
    onApplyFilter() {
      this.$emit("onApplyFilter", this.filter);
    },
    onResetFilter() {
      this.$emit("onResetFilter");
    },
  },
};
</script>

<style scoped>
.date-select {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
}
.filtering {
  padding: 15px;
  margin: 10px 0;
  border-radius: 15px;
  height: inherit;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  background-color: rgb(250, 250, 250);
}
.action {
  display: flex;
  flex-direction: row;
  justify-content: end;
  gap: 10px;
}
@media screen and (max-width: 400px) {
  .filtering {
    flex-direction: column;
  }
}
</style>